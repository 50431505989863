import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';


@Injectable({ 
    providedIn: 'root' 
})
export class RelatorioService {

    constructor(private http: HttpClient) { }

    getVendas() {
        return this.http.get<any>(`${environment.apiUrl}/relatorios/vendas`).pipe(map(res =>{ return res.response }));
    }

    getEntregas() {
        return this.http.get<any>(`${environment.apiUrl}/relatorios/entregas`).pipe(map(res =>{ return res.response }));
    }

    getEntregaDetalhes(id: number) {
        return this.http.get<any>(`${environment.apiUrl}/relatorios/entrega-detalhes/${id}`).pipe(map(res =>{ return res.response }));
    }

    getEstoque() {
        return this.http.get<any>(`${environment.apiUrl}/relatorios/estoque`).pipe(map(res =>{ return res.response }));
    }

    ////////////////////////////////////novo//////////////////////////////////////////
    // getEstoque() {
    //     return this.http.get(`${environment.apiUrl}/relatorios/estoque`, { responseType: 'json' });
    // }

    getVendidos() {
        return this.http.get<any>(`${environment.apiUrl}/relatorios/vendidos`).pipe(map(res =>{ return res.response }));
    }

    getClientes() {
         return this.http.get<any>(`${environment.apiUrl}/relatorios/clientes`).pipe(map(res =>{ return res.response }));
     }

    getCatalogo(queryParams: any = {}): Observable<any> {
        // Certifique-se de que 'categoria' e 'subcategoria' são os nomes corretos no backend
        const { categoria, subcategoria, ...restParams } = queryParams;

        const params = new HttpParams({
            fromObject: {
                ...restParams,
                ...(categoria ? { categoria } : {}),
                ...(subcategoria ? { subcategoria } : {}),
            }
        });

        return this.http.get(`${environment.apiUrl}/relatorios/catalogo`, { params, responseType: 'text' })
            .pipe(
                map(response => {
                    try {
                        const parsedResponse = JSON.parse(response);
                        return parsedResponse;
                    } catch (error) {
                        return response;
                    }
                })
            );
    }

    getVendaAReceber(id: number) {
        return this.http.get<any>(`${environment.apiUrl}/relatorios/venda-areceber/${id}`).pipe(map(res =>{ return res.response }));
    }

}
